
import './App.css';

import React from 'react';
import Slider from 'react-viewport-slider';

const wallpaper3 = 'https://i.imgur.com/41elHOy.jpg';
const wallpaper2 = 'https://i.imgur.com/PtQUWSz.jpg';
const wallpaper = 'https://i.imgur.com/8DEXe3q.jpg';

class App extends React.Component {
  render() {
    return (
      <Slider>
        <Slider.Item className="has-overlay-peach" style={{ backgroundImage: `url(${wallpaper2})`, backgroundSize: 'cover'}}  hideButton = {true}>
          <div className="content">CHEW</div>
        </Slider.Item>
        <Slider.Item className="has-overlay-green" style={{ backgroundImage: `url(${wallpaper3})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
          <div className="content">WITH</div>
          <div className="content">YOUR</div>
          <div className="content">MOUTH</div>
        </Slider.Item>
        <Slider.Item className="has-overlay-blue" style={{ backgroundImage: `url(${wallpaper})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
          <div className="content">CLOSED</div>
        </Slider.Item>
        <Slider.Item style={{ backgroundColor: '#353330' }}>
          <div className="content">.COM</div>
        </Slider.Item>
      </Slider>
    );
  }
};

export default App;
